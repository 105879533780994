<template>
    <v-container>
      <v-row>
          <v-col cols="12" class="primary--text text-h6">
            Realizar Recarga
          </v-col>
      </v-row>
      <v-row class="py-0">
          <v-col>
              <v-text-field
                label="Numero a 10 digitos"
                counter="10"
                class="py-0 my-0"
                v-model="numero"
                @blur="validarTipoPlan"
                :rules="[v=> v.length === 10|| 'El numero debe contener 10 digitos']"
              >
              </v-text-field>
          </v-col>
      </v-row>
      <v-row class="my-0 py-0">
          <v-col>
              <v-text-field
                label="Confirmar numero a 10 digitos"
                class="my-0 py-0"
                v-model="numero2"
                :rules="[v=> numero === numero2 || 'No coinciden los numeros']"
              >
              </v-text-field>
          </v-col>
      </v-row>
      <v-row class="my-0 py-0">
          <v-col class="primary--text text-h6 my-0 py-0">
              Seleccionar Recarga
          </v-col>
      </v-row>
      <v-row class="py-0 my-0">
        <v-col class="py-0">
            <v-select
                class="py-0 my-0 px-1"
                v-model="tipoOferta"
                :items="tiposOfertas"
                @change="cambiarOfertas"
                >
            </v-select>
        </v-col>
      </v-row>
      <v-row class="my-0 py-0">
          <v-col>
              <v-select
                :items="items"
                class="my-0 py-0"
                return-object
                item-value="IdAltan"
                :hint="plan.DescPlan"
                v-model="plan"
                >
                <!-- :item-text="item=> item.Nombre + ' - ' + item.DescPlan" -->
                <template slot="selection" slot-scope="{item}">
                    {{ item.Nombre }}
                </template>
                <template slot="item" slot-scope="{item}">
                    <v-card width="100%" class="px-0 mx-0">
                        <v-card-text class="text-h6 by-0 by-0 primary--text">{{ item.Nombre }}</v-card-text>
                        <v-card-subtitle class="mt-0 pt-0 font-weight-bold">${{ item.Costo }}</v-card-subtitle>
                        <v-card-subtitle class="mt-0 pt-0">{{ item.DescPlan }}</v-card-subtitle>
                    </v-card>
                </template>
              </v-select>
          </v-col>
      </v-row>
      <v-row class="my-0 py-0">
          <v-col class="primary--text text-h6 my-0 py-0">
              Metodo de pago
          </v-col>
      </v-row>
      <v-row class="my-0 py-0">
          <v-col>
              <v-select
                :items="items2"
                class="my-0 py-0"
                v-model="metodo"
              ></v-select>
          </v-col>
      </v-row>
      <v-row>
          <v-btn 
            block
            class="mt-5"
            color="primary"
            :disabled="CheckContinuar()"
            @click="Continuar"
            >Continuar</v-btn>
      </v-row>
      <v-snackbar
        rounded="pill"
        v-model="snackbar"
        min-width="0px"
        timeout="2000"
        >
        {{mensajeSnack}}
      </v-snackbar>
  </v-container>
</template>

<script>
export default {
    data(){
        return{
            rules: [v=> v.length <= 10 || '10 digits'],
            items: [],
            items2: ['Tarjeta de debito/Credito','Tienda'],
            numero: '',
            numero2: '',
            plan: '',
            metodo: '',
            tiposOfertas: ['PREPAGO', 'INTERNET HOGAR', 'INTERNET CON MOVILIDAD', 'PAQUETES PREPAGO'],
            tipoOferta: 'PREPAGO',
            snackbar: false,
            mensajeSnack: '',
        }
    },
    created(){
        this.$emit('muestra', false)
        this.GetPlanes().then(d=>{
            this.items = d.filter(dato=> (dato.Status==='ACTIVO' && dato.PrimSec==='SECUNDARIO' && dato.Tipo2===this.tipoOferta))
            this.items.sort((a,b)=>b.Costo-a.Costo)
        })
    },
    methods:{
        GetPlanes: async function(){
            const body = {
                opcion: 20
            }
            const params = {
                body: JSON.stringify(body),
                method: 'post'
            }
            const response = await fetch('https://maifon.mx/Conexion/crudCrecer.php', params)
            const data = await response.json()
            return data
        },
        Continuar: function(){
            this.getPerfil().then(d=>{
                if (d.responseSubscriber) {
                    if (d.responseSubscriber.status.subStatus === 'Active') {
                        const datos = {
                            numero: this.numero,
                            plan: this.plan,
                            metodo: this.metodo
                        }
                        this.$router.push({name: 'Recargas2', params: datos})
                    }
                }else{
                    this.mensajeSnack = 'El numero no esta activado'
                    this.snackbar = true
                }
            })
        },
        validarTipoPlan(){
            this.getPerfil().then(d=>{
                if (d.responseSubscriber) {
                    if (d.responseSubscriber.status.subStatus === 'Active') {
                        const idOffer = d.responseSubscriber.primaryOffering.offeringId

                        this.GetPlanes().then(dd=>{
                            const plan = dd.filter(dato=> (dato.IdAltan===idOffer))
                            if (plan) {
                              this.tipoOferta = plan[0].Tipo2
                              this.cambiarOfertas()
                            }
                        })
                    }
                }else{
                    this.mensajeSnack = 'El numero no esta activado'
                    this.snackbar = true
                }
            })
        },
        CheckContinuar: function(){
            return this.numero==='' || this.numero.length!==10 || this.numero!==this.numero2 || this.plan==='' || this.metodo===''
        },
        cambiarOfertas(){
            this.GetPlanes().then(d=>{
                this.items = d.filter(dato=> (dato.Status==='ACTIVO' && dato.PrimSec==='SECUNDARIO' && dato.Tipo2===this.tipoOferta))
                this.items.sort((a,b)=>b.Costo-a.Costo)
            })
        },
        async getPerfil(){
            const body = {
                token: localStorage.getItem('token'),
                numero: this.numero
            }
            const params = {
                body: JSON.stringify(body),
                method: 'post'
            }
            var resp = await fetch('https://maifon.mx/api/getPerfil.php', params)
            var data = await resp.json()
            return data
        }
    }
}
</script>